export const DASHBOARD_BASE_URL = 'polio/list';
export const CAMPAIGN_HISTORY_URL = 'polio/campaignHistory';
export const CALENDAR_BASE_URL = 'polio/calendar';
export const CONFIG_BASE_URL = 'polio/config';
export const CONFIG_COUNTRY_URL = `${CONFIG_BASE_URL}/country`;
export const CONFIG_REASONS_FOR_DELAY_URL = `${CONFIG_BASE_URL}/reasonsfordelay`;
export const LQAS_BASE_URL = 'polio/lqas';
export const LQAS_AFRO_MAP_URL = 'polio/lqas/lqas-map';
export const IM_GLOBAL = 'polio/im/global';
export const IM_OHH = 'polio/im/ohh';
export const IM_IHH = 'polio/im/ihh';
export const GROUPED_CAMPAIGNS = 'polio/groupedcampaigns';
export const BUDGET = 'polio/budget';
export const BUDGET_DETAILS = 'polio/budget/details';
export const VACCINE_MODULE = 'polio/vaccinemodule';
export const NOPV2_AUTH = `${VACCINE_MODULE}/nopv2authorisation`;
export const NOPV2_AUTH_DETAILS = `${NOPV2_AUTH}/details`;
export const VACCINE_SUPPLY_CHAIN = `${VACCINE_MODULE}/supplychain`;
export const VACCINE_SUPPLY_CHAIN_DETAILS = `${VACCINE_SUPPLY_CHAIN}/details`;
export const STOCK_MANAGEMENT = `${VACCINE_MODULE}/stockmanagement`;
export const STOCK_MANAGEMENT_DETAILS = `${STOCK_MANAGEMENT}/details`;
export const STOCK_VARIATION = `${STOCK_MANAGEMENT}/variation`;
export const NOTIFICATIONS_BASE_URL = 'polio/notifications';
