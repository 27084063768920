export const CHANGE_REQUEST = 'changeRequest';
const ORG_UNITS = 'orgunits';
const ORG_UNITS_CHANGE_REQUEST = `${ORG_UNITS}/${CHANGE_REQUEST}`;

export const baseUrls = {
    setupAccount: 'setupAccount',
    home: 'home',
    forms: 'forms/list',
    formDetail: 'forms/detail',
    mappings: 'forms/mappings',
    formsStats: 'forms/stats',
    mappingDetail: 'forms/mapping',
    instances: 'forms/submissions',
    instanceDetail: 'forms/submission',
    compareInstances: 'forms/compare',
    compareInstanceLogs: 'forms/compareInstanceLogs',
    orgUnits: 'orgunits/list',
    orgUnitsChangeRequest: ORG_UNITS_CHANGE_REQUEST,
    // orgUnitsChangeRequestList: `${ORG_UNITS_CHANGE_REQUEST}/list`,
    registry: 'orgunits/registry',
    registryDetail: 'orgunits/registry/details',
    groups: 'orgunits/groups',
    orgUnitTypes: 'orgunits/types',
    orgUnitDetails: 'orgunits/detail',
    links: 'orgunits/sources/links/list',
    algos: 'orgunits/sources/links/runs',
    completeness: 'forms/completeness',
    completenessStats: 'forms/completenessStats',
    users: 'settings/users',
    userRoles: 'settings/userRoles',
    projects: 'settings/projects',
    sources: 'orgunits/sources/list',
    sourceDetails: 'orgunits/source/details',
    tasks: 'settings/tasks',
    devices: 'settings/devices',
    entities: 'entities/list',
    entityTypes: 'entities/types',
    entityDuplicates: 'entities/duplicates',
    entityDuplicateDetails: 'entities/duplicates/details',
    polio: 'polio/list',
    pages: 'pages',
    planning: 'planning/list',
    assignments: 'planning/assignments',
    teams: 'settings/teams',
    entityDetails: 'entities/details',
    entitySubmissionDetail: 'entities/submission',
    storages: 'storages',
    storageDetail: 'storages/detail',
    workflows: 'workflows',
    workflowDetail: 'workflows/details',
    apiLogs: 'api/logs',
    error401: '401',
    error403: '403',
    error404: '404',
    error500: '500',
    modules: 'settings/modules',
    login: 'login',
    potentialPayments: 'payments/potential',
    lotsPayments: 'payments/lots',
};
